<template>
  <v-btn x-small
    :elevation="0"
    @click="handleShowPDF"
  >
    {{ $t('folders.showPDF', locale) }}
  </v-btn>
</template>
<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  export default{
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        base64: null,
        loading: false
      }
    },
    computed: {
      ...mapState('app', ['locale']),
    },
    methods: {
      handleShowPDF () {
        this.loading = true
        api.getAllWithoutLimit('dfiles', 'private/files/' + this.id.concat(`/${this.locale}/blob`))
          .then(response => {
            this.base64 = 'data:application/pdf;base64,' + response.data
            const linkSource = this.base64 
            const downloadLink = document.createElement("a")
            const fileName = "info.pdf"

            downloadLink.href = linkSource
            downloadLink.download = fileName
            downloadLink.click()
            this.loading = false
          })
      }
    }
  }
</script>
